import {Model} from "../Model";

export class OperationStep implements Model {
  id: string;
  loading: Promise<Model> | undefined;
  status: string;
  operation_step_type: string;
  operation_model: string;
}

export class OperationModel implements Model {
  id: string;
  loading: Promise<Model> | undefined;
  status: string;
  executorType: string;
  operationTypeId: string;
  operationType: string;
  readAccess: boolean;
  restrictions: any;
  operation_steps: OperationStep[];
}
