import {Component, OnDestroy, OnInit} from '@angular/core';
import {PrimusRouteService} from '../../core/primus-route.service';
import {ObjectEditService} from '../../core/object-edit.service';
import {CurrentObjectService} from '../../core/current-object.service';
import {ContentInfo} from '../../core/definitions/content-info';
import {MediaHelperService} from '../../core/media-helper.service';
import {SearchSelectorService} from '../../core/search-selector.service';
import {UiToolsService} from '../../core/ui-tools.service';
import {CommonsService} from '../../core/commons.service';
import {TemplateSelectorContainer} from '../../core/definitions/template-selector-container';
import {SelectorContainer} from '../../core/definitions/selector-container';
import {SectionMenuContainer} from '../../object-view/section-menu/section-menu.component';
import {Selector} from '../../core/definitions/reference';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {OperationAndSectionsContainerParams, OperationService} from '../../operations/operation.service';
import {OperationStepType} from '../../core/definitions/operation-step-type.enum';
import {OperationTarget} from '../../core/definitions/operation-target.enum';
import {OperationContainer} from '../../core/definitions/operation-container';
import {ContentMenusService} from '../../object-content-tab/content-menus.service';
import {SelectorCreationParams} from '../../core/definitions/selector-creation-params';
import {OperationExecutorType} from '../../core/definitions/operation-executor-type.enum';
import {PrimusRouterService} from '../../core/primus-router.service';
import {Subscription} from 'rxjs';
import {ContentListSourceContainer} from "../../core/definitions/object-content-tab/content-list-source-container";
import {FeatureFlagsService} from "../../core/feature-flags.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-object-page',
  templateUrl: './object-page.component.html',
  styleUrls: ['./object-page.component.scss']
})
export class ObjectPageComponent implements OnInit, OnDestroy {
  art = new SuperObjectModel();
  artifactId: string;
  sectionMenuContainer = new SectionMenuContainer();
  contentInfo: ContentInfo;
  templateSelectorContainer = new TemplateSelectorContainer();
  smallObjectMenus: boolean;
  mediumScreen: boolean;
  smallScreen: boolean;
  scrollContent: boolean;
  imageFullScreen = false;
  modelFullscreen = false;
  toggleAnnotations: boolean;
  contextIds: string[];
  isNew: boolean;
  reload = false;
  menus = [];
  status = 'closed';
  // Used to display annotation on image gallery
  annotation = {};
  loadFailed = false;
  operationContainer: OperationContainer;
  isEditing = false;
  finishedLoading = false;
  displayMediaSelectorsNewObjects = false;

  private templateGroupId: string;
  private stopSizeWatch: string;
  private routerSubscription: Subscription;

  constructor(private primusRouter: PrimusRouterService,
              private primusRoute: PrimusRouteService,
              private objectEditService: ObjectEditService,
              public currentObjectService: CurrentObjectService,
              private mediaHelper: MediaHelperService,
              private commons: CommonsService,
              private contentMenusService: ContentMenusService,
              private searchSelectorService: SearchSelectorService,
              private uiTools: UiToolsService,
              private operationService: OperationService,
              private featureFlagsService: FeatureFlagsService,
              private translate: TranslateService,
              private _snackbar: MatSnackBar) {
  }

  ngOnInit() {
    const featureFlags = this.featureFlagsService.getFeatureFlags();
    this.displayMediaSelectorsNewObjects = featureFlags.temporary.displayMediaSelectorsNewObjects;
    this.routerSubscription = this.primusRouter.navigationHandler(() => {
      this.init().then();
    });
    this.init().then();
  }

  ngOnDestroy(): void {
    if (this.stopSizeWatch) {
      this.uiTools.removeWindowSizeListener(this.stopSizeWatch);
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  get sectionsContainer() {
    return this.operationContainer?.rootSectionsContainer;
  }

  objectRefresh(event: any) {
    if (event.store) {
      this.operationContainer.rootSectionsContainer.submitted = true;
      if (!this.operationContainer.rootSectionsContainer.formGroup.invalid) {
        this.storeObject(true, true, false).then();
      }
    } else {
      this.loadObject().then();
    }
  }

  isFinishedLoading() {
    this.finishedLoading = true;
  }

  selectorOverlay() {
    if (this.templateSelectorContainer && this.templateSelectorContainer.selectorEnabled) {
      this.searchSelectorService.disableSelector(this.templateSelectorContainer);
    } else {
      this.disableSelector();
    }
  }

  onTemplateSelectorCreated(templateSelectorContainer: any) {
    this.templateSelectorContainer = templateSelectorContainer;
  }

  async onChangeTemplateGroup(newGroupId: string) {
    await this.primusRouter.navigateState(this.primusRouter.currentState(),
      {template_group_id: newGroupId},
      {location: 'replace', reload: true});
  }

  private async init() {
    this.contentInfo = new ContentInfo();
    this.currentObjectService.isEditing = this.primusRoute.params.edit === 'true';
    this.isEditing = this.currentObjectService.isEditing;
    this.templateGroupId = this.primusRoute.params.template_group_id;
    this.stopSizeWatch = this.uiTools.addWindowSizeListener((newVal: any) => {
        this.smallObjectMenus = newVal.width < 1025;
        this.mediumScreen = newVal.width < 1025;
        this.smallScreen = newVal.width < 642;
      }
    );
    this.artifactId = this.primusRoute.params.artifactId;
    this.contextIds = this.primusRoute.params.contextIds;
    const isNew = this.primusRoute.params.isNew === 'true';
    this.isNew = isNew && this.isEditing;
    this.menus = [];

    if (this.artifactId) {
      await this.loadObject();
    }

    const windowSize = this.uiTools.windowSize;
    this.mediumScreen = windowSize.width < 1025;
    this.smallObjectMenus = windowSize.width < 1025;
    this.smallScreen = windowSize.width < 642;

    if (this.isNew) {
      this._snackbar.open(this.translate.instant('TRANS__OBJECT_PAGE__NEW_OBJECT_REGISTERED'), null, {
        duration: 8000,
        panelClass: 'object-edit-new__snackbar-panel',
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      })
    }
  }

  private async loadObject() {
    this.art = null;
    try {
      this.operationContainer = await this.operationService.createOperationAndRootSectionsContainer(this.getCreateParams());
      this.operationContainer.refreshFn = () => {
        this.objectRefresh({});
      };
      await this.postLoadOperations();
    } catch (reason) {
      this.loadFailed = true;
      console.error(`Failed loading object: ${reason.error?.message || reason.message}`);
      this.art = new SuperObjectModel();
      this.art.artifact_id = this.artifactId;
    }
  }

  private getCreateParams(): OperationAndSectionsContainerParams {
    const params = {
      useExistingObject: true,
      objectId: this.artifactId,
      getSourceObject: !this.isEditing,
      operationTarget: OperationTarget.OBJECT_VIEW,
      templateGroupId: this.templateGroupId
    } as OperationAndSectionsContainerParams;
    if (this.isEditing) {
      params.executorType = OperationExecutorType.ARTIFACT;
      params.operationStepType = OperationStepType.EDIT_OBJECT;
    }
    return params;
  }

  private async postLoadOperations() {
    const sectionsContainer = this.operationContainer.rootSectionsContainer;
    this.sectionMenuContainer.artifact = sectionsContainer.rootObject;
    this.sectionMenuContainer.sections = sectionsContainer.sections;
    this.sectionMenuContainer.contentInfo = this.contentInfo;
    let listName = this.primusRoute.params.listName;
    if (this.contentInfo.curListName) {
      listName = this.contentInfo.curListName;
    }
    this.art = sectionsContainer.rootObject;
    this.currentObjectService.currentObject = sectionsContainer.rootObject;

    this.contentInfo.updateContentInfo(this.isEditing, this.art, listName);
    this.contentInfo.templateGroupId = this.templateGroupId;

    this.getMedia().then();
    this.operationContainer.contentInfo = this.contentInfo;
    this.operationContainer.enableSelector = (selector: Selector, params: SelectorCreationParams) => {
      this.enableSelector(selector, this.operationContainer, params);
    };
    this.operationContainer.disableSelector = () => {
      this.disableSelector();
    };
    this.setCurrentStepAttributes()
  }

  private setCurrentStepAttributes() {
    if (this.operationContainer?.currentOperation?.$$currentStep) {
      this.operationContainer.currentOperation.$$currentStep.$$hideRegisterStayOnStep = this.isNew;
      if (this.isEditing && this.isNew && this.displayMediaSelectorsNewObjects) {
        // Must skip dirty check in order to be able to upload media
        this.operationContainer.currentOperation.$$currentStep.skip_dirty_check = true;
      }
    }
  }

  private async storeObject(editing: boolean, edit: boolean, reload: boolean) {
    await this.objectEditService.setObjectValuesStoreObject(this.operationContainer.rootSectionsContainer);
    this.currentObjectService.isEditing = editing;
    this.isEditing = editing;
    if (reload) {
      await this.primusRouter.navigateState(
        this.primusRouter.currentState(),
        {edit: edit, isNew: undefined},
        {location: 'replace', reload: reload});
    } else {
      await this.loadObject();
    }
  }

  private async getMedia() {
    this.art.$$mediaContainer = await this.mediaHelper.getMediaContainerForAllObjectMedia(this.art);
  }

  private enableSelector(selector: Selector, mc: SelectorContainer, params?: SelectorCreationParams) {
    let restrictVal: string[];
    const contentListName = mc.currentMenu.content_list;
    const contentMenu = contentListName ? this.contentMenusService.getMenuFromListName(
      contentListName, this.contentInfo) : null;
    if (selector.context_restrict && contentMenu && contentMenu.count) {
      const contexts = this.commons.getContextIds(this.art.contexts);
      if (contexts && contexts.length > 0) {
        restrictVal = contexts;
      } else {
        restrictVal = [this.art.artifact_id];
      }
      if (!selector.context_restrict_field) {
        console.warn(`Selector "${selector.view}" missing context_restrict_field`);
      }
      selector.restrictions = [
        {
          fieldId: selector.context_restrict_field,
          value: restrictVal,
          on: true,
          title: 'TRANS__SELECTOR__CONTEXT_RESTRICTION'
        }

      ];
    }
    let currentSource: ContentListSourceContainer;
    if (contentListName && this.contentInfo.sources[contentListName]) {
      currentSource = this.contentInfo.sources[contentListName];
    }
    this.contentInfo.setSelectorUsed(selector, this.contentInfo.sources, currentSource);
    this.searchSelectorService.enableSelector(selector, mc, params,
      {
        searchContainerCreated: () => {
        },
        selectorCallback: (selectedObj: any) => {
          this.searchSelectorService.disableSelector(mc);
          mc.selectorCallback(selectedObj, selector);
        }
      }
    );
  }

  private disableSelector() {
    this.searchSelectorService.disableSelector(<SelectorContainer>this.operationContainer);
  }

  openImageFullScreen(activeImage: any) {
    this.toggleAnnotations = activeImage.toggleAnnotations;
    this.imageFullScreen = true;
  }

  openModelFullscreen(activeModel: any) {
    this.toggleAnnotations = false;
    this.modelFullscreen = true;
  }

  closeImageFullScreen() {
    this.imageFullScreen = false;
  }

  closeModelFullscreen() {
    this.modelFullscreen = false;
  }
}
