import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import {UiToolsService} from '../../core/ui-tools.service';
import {AConst} from '../../core/a-const.enum';
import {SearchParameterService} from '../search-parameter.service';
import {ResultViewService} from '../result-view.service';
import {SearchExecutorService} from '../search-executor.service';
import { SearchResultViewType } from '../../core/definitions/search-result-view-type.enum';
import {SearchContainer} from '../../core/definitions/search-container';

@Component({
  selector: 'app-search-result-view',
  templateUrl: './search-result-view.component.html',
  styleUrls: ['./search-result-view.component.scss']
})
export class SearchResultViewComponent implements OnInit, OnChanges, OnDestroy {

  @Input() searchContainer: SearchContainer;
  @Input() templateGroupId: string;

  AConst = AConst;
  SearchResultViewNames = SearchResultViewType;
  containerWidth: string;

  private unRegWindowWatch = null;
  private timeoutPromise: any;
  private lastScrollTop = 0;

  constructor(private elementRef: ElementRef,
              private uiTools: UiToolsService,
              private searchExecutorService: SearchExecutorService,
              private searchParameterService: SearchParameterService,
              private resultViewService: ResultViewService) {
  }

  ngOnInit() {
    this.searchExecutorService.subscribeToSearchResult(this.searchContainer, this.checkSetSearchRowsInit);
  }

  ngOnChanges(): void {
    this.searchContainer.scrollInfo.scrollElement = this.elementRef.nativeElement;
    this.setOverviewFields();

    if (this.unRegWindowWatch === null) {
      this.unRegWindowWatch = this.uiTools.addWindowSizeListener((newVal: any) => {
        if (newVal) {
          this.checkSetSearchRowsDelayed(newVal);
        }
      });
    }
    this.searchContainer.animateNewSearchResult = false;
  }

  get noSearchResultText() {
    let res = '';
    if (this.searchContainer.path === 'home/media_folders/folder') {
      res = 'TRANS__SEARCH_RESULT__NO_SEARCH_HITS__MEDIA_FOLDER'
    }
    return res;
  }

  ngOnDestroy(): void {
    if (this.unRegWindowWatch) {
      this.uiTools.removeWindowSizeListener(this.unRegWindowWatch);
    }
    this.searchExecutorService.unSubscribeToSearchResult(this.searchContainer, this.checkSetSearchRowsInit);
  }

  @HostListener('scroll', ['$event'])
  onScroll() {
    const deltaY = this.elementRef.nativeElement.scrollTop - this.lastScrollTop;
    this.searchExecutorService.runScrollSearch(this.searchContainer, deltaY);
    this.lastScrollTop = this.elementRef.nativeElement.scrollTop;
  }

  private setOverviewFields() {
    this.searchParameterService.getSearchFieldsSetOverviewFields(this.searchContainer).then();
  }

  private checkSetSearchRowsInit = () => {
    this.checkSetSearchRows(this.uiTools.windowSize);
  }

  // Delaying the setting of search rows due to window resize so that this code isn't run continuously whenever user
  // is in the process of resizing the window.
  private checkSetSearchRowsDelayed(windowSize: any) {
    if (this.timeoutPromise) {
      clearTimeout(this.timeoutPromise);
    }
    this.timeoutPromise = setTimeout(() => {
      this.checkSetSearchRows(windowSize);
    }, 500);
  }

  private checkSetSearchRows(windowSize: any) {
    this.containerWidth = windowSize.width > 1025 ? windowSize.width - this.uiTools.windowSize.sideMenuWidth + 'px' : windowSize.width + 'px';
    this.resultViewService.calculateAndSetsDefaultRowsColumns(this.searchContainer);

    const viewName = this.searchContainer.searchResultViewName;
    const oldRows = this.searchContainer.rows[viewName];
    const r = this.resultViewService.getDefaultCalculatedRowsColumns(viewName);
    let mustSearch = false;
    if (r.rows && r.rows !== oldRows) {
      mustSearch = true;
      this.searchContainer.rows[viewName] = r.rows;
    }
    if (mustSearch && !this.searchContainer.displayAll) {
      this.searchExecutorService.runSearch(this.searchContainer).then();
    }
  }

}
