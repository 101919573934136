import {Model} from "../Model";
import {FieldSection} from "./ReportUserGenerated";
import {ReportViewType} from "./ReportViewType";

export class ReportUserTemplate implements Model {
  id: string;
  loading: Promise<Model> | undefined;
  status: string;
  name: string;
  templateName: string;
  reportViewTypeId: string;
  reportViewOptions: string;
  fieldSections: FieldSection[] = [];
  reportViewType: ReportViewType
}
