<div class="container">
  <div class="header">
    <div class="heading-container">
      <ng-content select="[slot=heading]"></ng-content>
    </div>
    <div class="operations-container">
      @if (isSmallScreen()) {
        <button class="open-left-drawer" mat-flat-button (click)="sidenav.toggle()">
          <mat-icon class="material-symbols-outlined" matButtonIcon>view_sidebar</mat-icon>
          {{ 'TRANS__OBJECT_PAGE_V2__LAYOUT__SHOW_MENU' | translate }}
        </button>
      }
      <ng-content select="[slot=operations]"></ng-content>
    </div>
    <div class="search-navigation-container">
      <ng-content select="[slot=search-navigation]"></ng-content>
    </div>
  </div>
  <div class="content">
    <mat-sidenav-container [class.small-screen]="isSmallScreen()">
      <mat-sidenav class="left" #sidenav [opened]="!isSmallScreen()" [disableClose]="!isSmallScreen()" [mode]="isSmallScreen() ? 'over' : 'side'"
                   [fixedInViewport]="isSmallScreen()"
                   [fixedTopGap]="isSmallScreen() ? 50 : undefined" [fixedBottomGap]="0" position="start"
      >
        <div class="left-drawer-inner-container">
          @if (isSmallScreen()) {
            <div class="flex justify-end m-2">
              <button class="close-left-drawer" mat-button (click)="sidenav.close()">
                <mat-icon class="material-symbols-outlined" matButtonIcon>close</mat-icon>
                {{ 'TRANS__OBJECT_PAGE_V2__LAYOUT__CLOSE_MENU' | translate }}
              </button>
            </div>
          }
          <ng-content select="[slot=left-drawer]"></ng-content>
        </div>
      </mat-sidenav>

      <mat-sidenav-content>
        <ng-content></ng-content>
      </mat-sidenav-content>

      <mat-sidenav class="right" #rightDrawer [opened]="!isSmallScreen()" [mode]="isSmallScreen() ? 'over' : 'side'"
                   [fixedInViewport]="isSmallScreen()"
                   [fixedTopGap]="isSmallScreen() ? 50 : undefined" [fixedBottomGap]="0" position="end">
        <ng-content select="[slot=right-drawer]"></ng-content>
      </mat-sidenav>

    </mat-sidenav-container>

    <button class="toggle-right-drawer" mat-fab extended (click)="rightDrawer.toggle()">
      <mat-icon class="material-symbols-outlined">view_sidebar</mat-icon>
      <!-- todo: move label to parent component -->
      @if (rightDrawer.opened) {
        {{ 'TRANS__OBJECT_PAGE_V2__LAYOUT__HIDE_IMAGES' | translate }}
      } @else {
        {{ 'TRANS__OBJECT_PAGE_V2__LAYOUT__SHOW_IMAGES' | translate }}
      }
    </button>
  </div>
</div>
