import {Component, Input, OnChanges} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {RefParams, RefService, StateParams} from '../../core/ref.service';
import {SearchHandlerService} from '../../object-search/search-handler.service';
import {SuperObjectModel} from "../../core/definitions/super-object-model";
import {SearchContainer} from "../../core/definitions/search-container";
import {FeatureFlagsService} from '../../core/feature-flags.service';

@Component({
  selector: 'app-object-url',
  templateUrl: './object-url.component.html',
  styleUrls: ['./object-url.component.scss']
})
export class ObjectUrlComponent implements OnChanges {
  AConst = AConst;
  @Input() object: SuperObjectModel;
  @Input() searchItemIndex: number;
  @Input() rootObjId: string;
  @Input() rootObjType: string;
  @Input() searchContainer: SearchContainer;
  @Input() disable: boolean;
  @Input() listName: string;
  @Input() hasImage: boolean;
  @Input() useUrl: boolean;
  @Input() aClass: string;

  isDisabled: boolean;
  uiOptions = {
    reload: true
  };
  routerLink: any[];
  queryParams: any;
  externalLink: string;

  constructor(private ref: RefService,
              private searchHandler: SearchHandlerService,
              private featureFlagService: FeatureFlagsService) {
  }

  ngOnChanges() {
    this.getRef().then(() => {
      if (this.featureFlagService.getFeatureFlags().experimental.useNewObjectPage && this.object?.artifact_id) {
        if (this.routerLink.join('/').includes('/artifact')) {
          this.routerLink.push(this.object.artifact_id);
        }
      }
    });
    this.isDisabled = this.disable || !this.object;
    if (this.isDisabled) {
      this.uiOptions.reload = false;
    }
  }

  async getRef(): Promise<void> {
    if (this.object.artifact_id) {
      const params = new RefParams(this.object, {
        listName: null
      } as StateParams);
      params.searchItemIndex = null;
      params.rootObjId = null;
      if (this.searchItemIndex !== undefined) {
        params.searchItemIndex = this.searchItemIndex;
      }
      if (this.rootObjId !== undefined) {
        params.rootObjId = this.rootObjId;
        params.rootObjType = this.rootObjType;
      }
      if (this.listName) {
        params.stateParams.listName = this.listName;
      }
      const refData = await this.ref.makeRef(params);
      if (refData?.param.externalLink) {
        this.externalLink = refData.param.externalLink;
      } else if (refData) {
        this.externalLink = refData.param.externalLink;
        this.routerLink = refData.routerLink;
        this.queryParams = refData.queryParams;
        // Delete parentId
        delete this.queryParams.parentId;
        this.isDisabled = this.isDisabled || refData.disabled;
      } else {
        this.isDisabled = true;
      }
    }
  }

  clickExternalItem(href: string) {
    window.open(href, '_blank', 'noopener, noreferrer');
  }

  clickItem() {
    if (this.searchContainer && this.searchItemIndex !== undefined) {
      this.searchHandler.setSearchItemIndex(this.searchContainer, this.searchItemIndex);
    }
  }
}
