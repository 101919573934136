<div class="heading">
  @if (loading) {
    <mat-spinner diameter="14" style="margin: .25rem auto;"></mat-spinner>
  } @else {
    @if (image) {
      <img src="{{ image }}">
    }
    <span class="title">{{ title }}</span>

    @if (infoMenuContent.childElementCount > 0) {
      <div class="info-icon-button-wrapper">
        <button
          class="info-icon-button"
          mat-icon-button
          type="button"
          [matMenuTriggerFor]="infoMenu"
        >
          <mat-icon class="material-symbols-outlined info-icon">info</mat-icon>
        </button>
      </div>
    }

    <mat-menu #infoMenu="matMenu" class="object-page-header__information-popover-mat-menu">
      <div #infoMenuContent>
        <ng-content select="[slot=information-popover-content]"></ng-content>
      </div>
    </mat-menu>
  }
</div>
