<app-m3-wrapper>
  <h2 mat-dialog-title>{{ 'TRANS__CREATE_OBJECT_DIALOG__TITLE' | translate }}</h2>
  <mat-dialog-content>

    <p>{{ 'TRANS__CREATE_OBJECT_DIALOG__SUBTITLE' | translate }}</p>

    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">

      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <mat-action-list>
          <mat-list-item (click)="onLeafNodeClick($event, node)">
            <div class="leaf-node">
              <div class="icon">
                <mat-icon class="material-symbols-outlined">{{ node.icon }}</mat-icon>
              </div>
              <div class="flex-grow">{{ node.label | translate }}</div>
              <div class="tooltip">
                <button mat-icon-button matTooltip="{{node.tooltip | translate}}">
                  <mat-icon class="material-symbols-outlined">help</mat-icon>
                </button>
              </div>
            </div>
          </mat-list-item>
        </mat-action-list></mat-tree-node>

      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodeToggle>
        <mat-action-list>

          <mat-list-item>
            <li>
              <div class="mat-tree-node">
                <div class="flex-grow">{{node.label | translate}}</div>
                <mat-icon class="material-symbols-outlined">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </div>
            </li>
          </mat-list-item>
          <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
          </ul>
        </mat-action-list>
      </mat-nested-tree-node>
    </mat-tree>



  </mat-dialog-content>
</app-m3-wrapper>
