import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SearchObject} from '../../../core/definitions/search-object';
import {SearchResultSelectionsService} from '../../search-result-selections.service';
import {SearchHandlerService} from '../../search-handler.service';
import {VirtualScrollSearchDataSourceColumns} from '../../virtual-scroll-search-data-source-columns';
import {SearchExecutorService} from '../../search-executor.service';
import {ResultViewService} from '../../result-view.service';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {SearchContainer} from '../../../core/definitions/search-container';
import {FeatureFlagsService} from '../../../core/feature-flags.service';

@Component({
  selector: 'app-search-result-view-gallery',
  templateUrl: './search-result-view-gallery.component.html',
  styleUrls: ['./search-result-view-gallery.component.scss']
})
export class SearchResultViewGalleryComponent implements OnInit {
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
  @Input() searchContainer: SearchContainer;
  @Input() width: string;

  ds: VirtualScrollSearchDataSourceColumns;

  readonly objectPageV2 = this.featureFlags.getFeatureFlags().experimental.useNewObjectPage;
  constructor(private searchResultSelectionsService: SearchResultSelectionsService,
              private searchHandler: SearchHandlerService,
              private searchExecutorService: SearchExecutorService,
              public resultViewService: ResultViewService,
              private featureFlags: FeatureFlagsService) {
  }

  ngOnInit() {
    this.ds = new VirtualScrollSearchDataSourceColumns(this.searchExecutorService, this.searchContainer);
    if (this.searchContainer.virtualScrollViewOffset) {
      setTimeout(() => {
        this.virtualScroll.scrollToOffset(this.searchContainer.virtualScrollViewOffset);
      }, 2000);
    }
  }

  select(obj: SearchObject, event: any) {
    this.searchResultSelectionsService.selectSearchResultItem(obj, this.searchContainer, event.shiftKey);
  }

  setSearchItemIndex(searchItemIndex: number) {
    if (this.searchContainer && searchItemIndex !== undefined) {
      this.searchContainer.virtualScrollViewOffset = this.virtualScroll.getOffsetToRenderedContentStart();
      this.searchHandler.setSearchItemIndex(this.searchContainer, searchItemIndex);
    }
  }

  getRouterLink(art: SearchObject) {
    if (art.$$refData?.routerLink?.join('/')?.includes('/artifact') && this.objectPageV2) {
      return [...art.$$refData.routerLink, art.artifact_id];
    }
    return art.$$refData?.routerLink
  }
}
