import {InjectionToken, NgModule} from '@angular/core';
import {ActivatedRouteSnapshot, RouterModule, Routes} from '@angular/router';
import {MainMenuComponent} from '../main-menu/main-menu/main-menu.component';
import {SearchPageComponent} from '../object-search/search-page/search-page.component';
import {
  AdvancedSearchComponent
} from '../object-search/advanced-search-and-search-category/advanced-search/advanced-search.component';
import {FaqPageComponent} from '../administration/faq-page/faq-page.component';
import {ReportsIndexComponent} from '../administration/reports/index/reports-index.component';
import {ImageAnnotationComponent} from '../image-annotation/image-annotation/image-annotation.component';
import {ProcedureComponent} from '../spectrum-procedure/procedure/procedure.component';
import {InformationComponent} from '../information-page/information/information.component';
import {AboutPrimusComponent} from '../administration/about-primus/about-primus.component';
import {AdminMainPageComponent} from '../administration/admin-main-page/admin-main-page.component';
import {AdminSettingsComponent} from '../administration/admin-settings/admin-settings.component';
import {AdminCreateNewComponent} from '../administration/admin-create-new/admin-create-new.component';
import {AdminTemplateGroupComponent} from '../administration/admin-template-group/admin-template-group.component';
import {AdminTemplateModelComponent} from '../administration/admin-template-model/admin-template-model.component';
import {AdminConceptListsComponent} from '../administration/admin-concept-lists/admin-concept-lists.component';
import {AdminCollectionComponent} from '../administration/admin-collection/admin-collection.component';
import {AdminIdFormatComponent} from '../administration/admin-id-format/admin-id-format.component';
import {AdminUsersComponent} from '../administration/admin-users/admin-users.component';
import {
  AdminUsersProfilePageComponent
} from '../administration/admin-users/admin-users-profile-page/admin-users-profile-page.component';
import {AdminLocationComponent} from '../administration/admin-location-place/admin-location/admin-location.component';
import {AdminPlaceComponent} from '../administration/admin-location-place/admin-place/admin-place.component';
import {AuthenticatorComponent} from "../authenticator/authenticator.component";
import {CanDeactivateInformationPage} from '../information-page/can-deactivate-information-page';
import {CanDeactivateProcedurePage} from '../spectrum-procedure/can-deactivate-procedure-page';
import {AdminAgentComponent} from '../administration/admin-agent/admin-agent.component';
import {AdminNamedObjectComponent} from '../administration/admin-named-object/admin-named-object.component';
import {MediaPageComponent} from '../media-page/media-page/media-page.component';
import {ObjectPageComponent} from '../object-page/object-page/object-page.component';
import {CanDeactivateObjectPage} from '../object-page/object-page/can-deactivate-object-page';
import {CanDeactivateMediaPage} from '../media-page/media-page/can-deactivate-media-page';
import {ObjectNewPageComponent} from '../create-object/object-new-page/object-new-page.component';
import {CanDeactivateObjectNewPage} from '../create-object/object-new-page/can-deactivate-object-new-page';
import {AdminServiceComponent} from '../administration/admin-service/admin-service.component';
import {
  AdminServiceFieldsComponent
} from '../administration/admin-service/admin-service-fields/admin-service-fields.component';
import {AdminServiceApiComponent} from "../administration/admin-service/admin-service-api/admin-service-api.component";
import {
  AdminServiceIndexComponent
} from "../administration/admin-service/admin-service-index/admin-service-index.component";
import {NotFoundComponent} from "./not-found/not-found.component";
import {
  AdminMassMediaImportComponent
} from "../administration/admin-mass-media-import/admin-mass-media-import.component";
import {
  AdminServiceImportComponent
} from "../administration/admin-service/admin-service-import/admin-service-import.component";
import {AdminAddressComponent} from "../administration/admin-address/admin-address.component";
import {ReportTemplatesComponent} from "../administration/reports/report-templates/report-templates.component";
import {ReportStandardComponent} from "../administration/reports/report-standard/report-standard.component";
import {ReportGeneratedComponent} from "../administration/reports/report-generated/report-generated.component";
import {
  EditReportTemplateComponent
} from "../administration/reports/report-templates/edit-report-template/edit-report-template.component";
import {ReportPreviewComponent} from "../administration/reports/report-preview/report-preview.component";
import {WelcomeScreenComponent} from '../welcome-screen/welcome-screen.component';
import {EditReportComponent} from "../administration/reports/report-generated/edit-report/edit-report.component";
import {
  AdminServiceFlagsComponent
} from '../administration/admin-service/admin-service-flags/admin-service-flags.component';
import {
  ObjectPageV2OverviewComponent
} from '../object-page-v2/object-page-v2-overview/object-page-v2-overview.component';
import {ObjectPageV2Component} from '../object-page-v2/object-page-v2.component';
import {ObjectPageV2SubpageComponent} from '../object-page-v2/object-page-v2-subpage/object-page-v2-subpage.component';
import {
  ObjectPageV2ChangelogComponent
} from '../object-page-v2/object-page-v2-changelog/object-page-v2-changelog.component';
import {ReportDraftComponent} from "../administration/reports/report-draft/report-draft.component";

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');
const objectPageV2Children: Routes = [
  {
    path: '', redirectTo: 'overview', pathMatch: 'full',
  },
  {
    path: 'overview', component: ObjectPageV2OverviewComponent
  },
  {
    path: 'changelog', component: ObjectPageV2ChangelogComponent
  },
  // TODO: 02/07/2024 Create new custom components for each sub-menu, like "relations"
  {
    path: ':segment',
    component: ObjectPageV2SubpageComponent
  }
];

const routes: Routes = [
  {path: 'home', component: WelcomeScreenComponent},
  {path: 'primus', component: MainMenuComponent},
  {
    path: 'search',
    component: SearchPageComponent,
    children: [
      {path: 'advanced-search', component: AdvancedSearchComponent},
      {path: 'artifact', component: ObjectPageComponent, runGuardsAndResolvers: 'always', canDeactivate: [CanDeactivateObjectPage]},

      // new urls for redesigned object page
      {
        path: 'artifact/:artifactId',
        component: ObjectPageV2Component,
        children: objectPageV2Children,
        runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
        canDeactivate: [CanDeactivateObjectPage]
      },
      {path: 'gallery', component: MediaPageComponent, canDeactivate: [CanDeactivateMediaPage]},
      {path: 'video-gallery', component: MediaPageComponent, canDeactivate: [CanDeactivateMediaPage]},
      {path: 'annotate', component: ImageAnnotationComponent},
      {path: 'procedure', component: ProcedureComponent, canDeactivate: [CanDeactivateProcedurePage]},
      {path: 'information', component: InformationComponent, canDeactivate: [CanDeactivateInformationPage]},
    ],
    runGuardsAndResolvers: 'always'
  },
  {path: 'reports', component: ReportsIndexComponent, children: [
      {path: 'report-templates', component: ReportTemplatesComponent},
      {path: 'standard-reports', component: ReportStandardComponent},
      {path: 'generated-reports', component: ReportGeneratedComponent},
      {path: 'report-drafts', component: ReportDraftComponent}
    ],
  },
  {path: 'reports/generated-reports/:report_id', component: EditReportComponent},
  {path: 'reports/report-templates/:report_id', component: EditReportTemplateComponent},
  {path: 'report-preview/:report_id', component: ReportPreviewComponent},
  {path: 'artifact', component: ObjectPageComponent, runGuardsAndResolvers: 'always', canDeactivate: [CanDeactivateObjectPage]},
  {
    path: 'artifact/:artifactId',
    component: ObjectPageV2Component,
    children: objectPageV2Children,
    runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
    canDeactivate: [CanDeactivateObjectPage]
  },
  {path: 'gallery', component: MediaPageComponent, canDeactivate: [CanDeactivateMediaPage]},
  {path: 'video-gallery', component: MediaPageComponent, canDeactivate: [CanDeactivateMediaPage]},
  {path: 'annotate', component: ImageAnnotationComponent},
  {path: 'procedure', component: ProcedureComponent, canDeactivate: [CanDeactivateProcedurePage]},
  {path: 'information', component: InformationComponent, canDeactivate: [CanDeactivateInformationPage]},
  {path: 'faq', component: FaqPageComponent},
  {path: 'about', component: AboutPrimusComponent},
  {path: 'admin-page', component: AdminCreateNewComponent},
  {path: 'admin', component: AdminMainPageComponent},
  {path: 'mass-media-import', component: AdminMassMediaImportComponent},
  {path: 'settings', component: AdminSettingsComponent},
  {path: 'template-group', component: AdminTemplateGroupComponent},
  {path: 'template-model', component: AdminTemplateModelComponent},
  {path: 'concept-lists', component: AdminConceptListsComponent},
  {path: 'collection', component: AdminCollectionComponent},
  {path: 'id-format', component: AdminIdFormatComponent},
  {path: 'users', component: AdminUsersComponent},
  {path: 'profile', component: AdminUsersProfilePageComponent},
  {path: 'locations', component: AdminLocationComponent},
  {path: 'places', component: AdminPlaceComponent},
  {path: 'agents', component: AdminAgentComponent},
  {path: 'addresses', component: AdminAddressComponent},
  {path: 'named-object', component: AdminNamedObjectComponent},
  {path: 'service', component: AdminServiceComponent},
  {path: 'fields', component: AdminServiceFieldsComponent},
  {path: 'api', component: AdminServiceApiComponent},
  {path: 'flags', component: AdminServiceFlagsComponent},
  {path: 'index', component: AdminServiceIndexComponent},
  {path: 'import', component: AdminServiceImportComponent},
  {path: 'new', component: ObjectNewPageComponent, canDeactivate: [CanDeactivateObjectNewPage]},
  {path: 'authenticate', component: AuthenticatorComponent},
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'externalRedirect', resolve: {url: externalUrlProvider}, component: NotFoundComponent},
  // redirecting to home here is fine - code exchange should still work.
  // there must be a route to prevent route-mismatch errors.
  {path: 'oauth2/callback', redirectTo: 'home', pathMatch: 'full'},
  {path: '**', component: NotFoundComponent}
];

@NgModule({
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalUrl = route.paramMap.get('externalUrl');
        window.open(externalUrl, '_self');
      }
    }
  ],
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
