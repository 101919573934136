@if (!generatedReports || generatedReports.status === 'loading') {
  <div class="loading-spinner">Laster dine rapportmaler...</div>
} @else if (generatedReports.status === 'loaded') {
  <div class="report-overview-toolbar" xmlns="http://www.w3.org/1999/html">
    <app-admin-selection-action-menu [selectedItems]="selectedReports">
      <app-admin-selection-action label="Hent PDF-filer" [action]="getPdfs"></app-admin-selection-action>
      <app-admin-selection-action label="Slett Rapporter" [action]="deleteReports"></app-admin-selection-action>
    </app-admin-selection-action-menu>

    <input matInput
           placeholder="Filtrer i rapportlisten"
           [(ngModel)]="searchText"
           (ngModelChange)="filterReports()" >
  </div>

  @for(template of filteredReports; track template.id) {
    <div class="report-overview-box">
      <div class="report-preview-image">
        <mat-checkbox
          [checked]="selectedReports.includes(template.id)"
          (change)="reportSelectionChanged($event, template.id)">
        </mat-checkbox>
      </div>
      <div class="report-preview-icon">
        <i class="material-icons">insert_drive_file</i>
      </div>
      <div class="report-overview-fields" [routerLink]="['/reports/generated-reports', template.id]">
          <h1>{{template.name}}</h1>
          <label>Beskrivelse: <div class="report-overview-field">{{template.shortDescription}}</div></label>
          <label>Type: <div class="report-overview-field">Egendefinert rapport</div></label>
          <label>Rapportmal: <div class="report-overview-field">{{template.reportViewType ? template.reportViewType.name : 'Ingen raportmal valgt'}}</div></label>
          <label>Status: <div class="report-overview-field">{{template.reportStatus}}</div></label>
      </div>
    </div>
  } @empty {
    <div class="report-overview-box">Ingen rapporter i listen</div>
  }
} @else {
  <div class="report-overview-box">Ingen rapporter i listen</div>
}

<router-outlet></router-outlet>
