<div class="admin-selection-action-menu" [ngClass]="selectedItems.length > 0 ? 'admin-selection-action-menu-active' : 'admin-selection-action-menu-disabled'" (click)="toggleActions()">
  <label>
    Behandle utvalg ({{selectedItems.length}})
  </label>
  <i class="material-icons">keyboard_arrow_down</i>
</div>

<!-- Popup container for actions -->
@if(showActions) {
  <div class="action-popup-overlay" (click)="closeActionPopup()"></div>

  <div class="action-popup">
    <ng-content></ng-content> <!-- Projected action items here -->
  </div>
}
