import {TwoWayMap} from "../TwoWayMap";
import {DefaultModelAdapter} from "../DefaultModelAdapter";
import {OperationModel} from "../models/Operation";

export class OperationAdapter<OperationModel> extends DefaultModelAdapter<OperationModel> {
  keyConversionMap: TwoWayMap = new TwoWayMap();

  constructor(singularKey: string, pluralKey: string) {
    super(singularKey, pluralKey);
    this.singularKey = singularKey;
    this.pluralKey = pluralKey;
    this.urlPrefix = '/cms_api/v1.0/meta_operation';
    this.urlForId = null;
    this.urlForList = null;

    this.keyConversionMap.set("operationTypeId", "operation_type_id");
    this.keyConversionMap.set("operationType", "operation_type");
    this.keyConversionMap.set("readAccess", "read_access");
    this.keyConversionMap.set("operation_steps", "operation_steps");
  }

  /*
    The return from this API call is a JSON Array, not a JSON object. Extract and return the first JSON object
   */
  fromJsonObject(jsonString: any): OperationModel {
    let operationList : OperationModel[] = this.fromJsonList(jsonString);
    console.log('---------operationList', operationList);
    if (operationList && operationList.length > 0) {
      let operation : OperationModel = this.parseJSON(operationList[0]);
      // @ts-ignore
      operation.id = operationList[0].executor_type;
      console.log('---------operation', operation);
      return operation;
    }
  }
}
