import {TwoWayMap} from "../TwoWayMap";
import {DefaultModelAdapter} from "../DefaultModelAdapter";

export class OperationStatusAdapter<OperationStatus> extends DefaultModelAdapter<OperationStatus> {
  keyConversionMap: TwoWayMap = new TwoWayMap();

  constructor(singularKey: string, pluralKey: string) {
    super(singularKey, pluralKey);
    this.singularKey = singularKey;
    this.pluralKey = pluralKey;
    this.urlPrefix = '/cms_api/v1.0/meta_operation';
    this.urlForId = null;
    this.urlForList = null;
  }
}
