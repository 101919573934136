import {ModelCache} from "./ModelCache";
import {ModelCacheArray} from "./ModelCacheArray";
import {Model} from "./Model";
import {ModelAdapter} from "./ModelAdapter"
import {UrlQueryParam} from "./UrlQueryParam";
import {DefaultModelAdapter} from "./DefaultModelAdapter";
import {AccessTokenService} from "../access-token.service";
import {Injectable} from "@angular/core";
import {PrimusBackendInstanceService} from "../primus-backend-instance.service";

@Injectable({
  providedIn: 'root'
})
export class ModelStore {
  private modelCache: ModelCache;
  private registeredAdapters: Map<string, ModelAdapter<any>>;
  private accessTokenService: AccessTokenService;

  constructor(accessTokenService: AccessTokenService) {
    console.log('*******');
    console.log(accessTokenService);

    this.accessTokenService = accessTokenService;
    this.modelCache = new ModelCache();
    this.registeredAdapters = new Map<string, ModelAdapter<any>>();
  }

  public registerModelFor(singularKey: string, pluralKey: string, classInstance: any) {
    this.modelCache.registerCacheFor(singularKey, pluralKey, classInstance);
  }

  public registerAdapterFor<T>(singularKey: string, adapter: ModelAdapter<T>) {
    this.registeredAdapters.set(singularKey, adapter);
  }

  public getRegisteredAdpaterFor<T>(singularKey: string): ModelAdapter<T> | undefined {
    let modelType = this.modelCache.getModelTypeFor(singularKey);

    if (modelType) {
      if (!this.registeredAdapters.has(singularKey)) {
        let adapter: DefaultModelAdapter<T> = new DefaultModelAdapter<T>(singularKey, modelType.pluralKey);
        this.registeredAdapters.set(singularKey, adapter);
      }
    } else {
      console.log("Before registering adaptors, the model should be registered via registerModelFor(singularKey, pluralKey, url);");
    }

    return this.registeredAdapters.get(singularKey);
  }

  public findModel<T extends new (...args: any[]) => any>(modelSingularKey: string, id: string, ...queryParams: UrlQueryParam[]) {
    const apiUrl = PrimusBackendInstanceService.getApiUrl();
    if (!apiUrl) {
      throw new Error('[MODEL-STORE] -- API URL not set');
    }
    let modelType = this.modelCache.getModelTypeFor(modelSingularKey);
    let foundModel = this.modelCache.getModel(modelSingularKey, id);
    let foundAdapter = this.getRegisteredAdpaterFor(modelSingularKey);

    console.log('------ found model before cache: ', foundModel);

    if (modelType !== undefined && foundModel === undefined && foundAdapter !== undefined) {
      //Create new, empty model
      foundModel = new modelType.classInstance();
      foundModel.id = id;
      foundModel.status = 'loading';


      //Fetch
      //Be sure to add the loading promise to the returned foundModel
      foundModel.loading = new Promise((resolve, reject) => {
        // @ts-ignore
        fetch(apiUrl + foundAdapter.getUrlForId(id, queryParams), {
          method: 'GET',
          headers: this.buildHeaders()
        })
          .then(async (response) => {
            if (!response.ok) {
              this.generateErrorResponse(foundModel,
                "" + response.status,
                // @ts-ignore
                'Unable to fetch data from API: ' + foundAdapter.getUrlForId(id, queryParams) + "/" + id,
                'Unable to Fetch data from the API.'
              );

              reject(foundModel);
            } else {
              let data = await response.json();
              // @ts-ignore
              let jsonReturn: InstanceType<T> = foundAdapter.fromJsonObject(data);
              // Create a new instance of the expected class and assign the properties from the fetched data to it
              jsonReturn = Object.assign(new modelType.classInstance, jsonReturn);
              this.modelCache.setModel(modelSingularKey, jsonReturn);

              let cachedModel = this.modelCache.getModel(modelSingularKey, id) as InstanceType<T>;
              //keep foundModel the same instance, assign new properties to it
              Object.assign(foundModel, {...cachedModel});

              //perform sideloading
              this.sideLoadRegisteredModels(foundAdapter.generateSimpleJsonApi(data), modelSingularKey);

              foundModel.status = 'loaded';
              // Resolve the promise with the updated object
              resolve(foundModel);
            }
          })
          .catch(() => {
            this.generateErrorResponse(foundModel,
              "503",
              // @ts-ignore
              '503 Service Unavailable',
              'Unable to fetch data from API: \' + modelType.getStandardUrl() + "/" + id'
            );

            reject(foundModel);
          });
      });
    }

    return foundModel;
  }

  private generateErrorResponse(foundModel: any, errorCode: string, errorMessage: string, errorDetail: string) {
    foundModel.status = 'error';

    foundModel.errorMessage = {
      errorCode: errorCode,
      // @ts-ignore
      errorMessage: errorMessage,
      errorDetail: errorDetail
    };
  }

  public findAllModels<T>(modelSingularKey: string, ...queryParams: UrlQueryParam[]) {
    const apiUrl = PrimusBackendInstanceService.getApiUrl();
    if (!apiUrl) {
      throw new Error('[MODEL-STORE] -- API URL not set');
    }

    let modelType = this.modelCache.getModelTypeFor(modelSingularKey);
    this.modelCache.clearModels(modelSingularKey);
    let foundModels: ModelCacheArray<T> = new ModelCacheArray<T>();
    let foundAdapter = this.getRegisteredAdpaterFor(modelSingularKey);

    if (modelType && foundAdapter) {
      foundModels.status = 'loading';
      foundModels.loading = new Promise((resolve, reject) => {
        // @ts-ignore
        fetch(apiUrl + foundAdapter.getUrlForList(queryParams), {
          method: 'GET',
          headers: this.buildHeaders()
        }).then(async (response) => {
          if (!response.ok) {
            this.generateErrorResponse(foundModels,
              "" + response.status,
              // @ts-ignore
              'Unable to fetch all data from API: ' + modelType.getStandardUrl(),
              'Unable to Fetch data from the API.');

            reject(foundModels);
          } else {
            let data = await response.json();

            console.log("ModelStore.findAll -> then: " + modelType?.pluralKey);

            // @ts-ignore
            let jsonReturn: T[] = foundAdapter?.fromJsonList(data); //data[modelType?.pluralKey];
            jsonReturn.forEach((jsonModel: any) => {
              // Create a new instance of the expected class and assign the properties from the fetched data to it
              let model = Object.assign(new modelType.classInstance, jsonModel);
              this.modelCache.setModel(modelSingularKey, model);

              foundModels.push(model);
            })
            foundModels.status = 'loaded';
            // Resolve the promise with the updated object

            //perform sideloading
            this.sideLoadRegisteredModels(data, modelSingularKey);

            resolve(foundModels);
          }
        }).catch((err) => {
          console.log(err);
          this.generateErrorResponse(foundModels,
            "503",
            // @ts-ignore
            '503 Service Unavailable',
            'Unable to fetch data from API: \' + modelType.getStandardUrl() + "/" + id'
          );
          reject(foundModels);
        });
      });
    }

    return foundModels;
  }

  private sideLoadRegisteredModels<T>(data: any, mainSingularKey: string) {
    this.modelCache.getRegisteredCaches().forEach(modelType => {
      if (modelType.singularKey != mainSingularKey) {
        let foundAdapter = this.getRegisteredAdpaterFor(modelType.singularKey);
        // @ts-ignore
        let jsonReturn = foundAdapter.fromJsonList(data);//data[modelType.pluralKey];
        jsonReturn.forEach((jsonModel: any) => {
          //let modelObject: T = this.parseJSON(jsonModel);
          this.modelCache.setModel(modelType.singularKey, jsonModel);
        });
      }
    });
  }

  public deleteModel<T>(modelSingularKey: string, id: string) {
    const apiUrl = PrimusBackendInstanceService.getApiUrl();
    if (!apiUrl) {
      throw new Error('[MODEL-STORE] -- API URL not set');
    }

    let modelType = this.modelCache.getModelTypeFor(modelSingularKey);
    let foundModel = this.modelCache.getModel(modelSingularKey, id);
    let foundAdapter = this.getRegisteredAdpaterFor(modelSingularKey);

    if (foundModel) {
      foundModel.status = 'deleting';
    }

    if (modelType && foundAdapter) {
      foundModel.loading = new Promise((resolve) => {
        // @ts-ignore
        fetch(apiUrl + foundAdapter.getUrlForId(id, null), {
          method: 'DELETE',
          headers: this.buildHeaders(),
        }
      ).then(() => {
          console.log("ModelStore.delete -> then: " + modelSingularKey);
          this.modelCache.deleteModel(modelSingularKey, id);
          foundModel = undefined;
          resolve(null);
        });
      });
    }

    return foundModel;
  }

  public createModel<T>({modelSingularKey, record}: { modelSingularKey: string, record: Model }) {
    const apiUrl = PrimusBackendInstanceService.getApiUrl();
    if (!apiUrl) {
      throw new Error('[MODEL-STORE] -- API URL not set');
    }

    let modelType = this.modelCache.getModelTypeFor(modelSingularKey);
    let foundAdapter = this.getRegisteredAdpaterFor(modelSingularKey);

    record = Object.assign(new modelType.classInstance, record);
    if (modelType && foundAdapter) {
      record.status = 'inserting';

      record.loading = new Promise((resolve) => {
        // @ts-ignore
        let data = foundAdapter.toJsonObject(record);

        // @ts-ignore
        fetch(apiUrl + foundAdapter.getUrlForList(), {
          method: 'POST',
          body: data, //JSON.stringify(dataObj),
          headers: this.buildHeaders(),

        }).then((response) => response.json())
          .catch((err) => {
            record.status = 'error';
            console.log(err);
          })
          .then((data) => {
            console.log("ModelStore.create -> then: " + modelSingularKey);

            // @ts-ignore
            let jsonReturn: InstanceType<T> = foundAdapter.fromJsonObject(data);

            // Create a new instance of the expected class and assign the properties from the fetched data to it
            jsonReturn = Object.assign(new modelType.classInstance, jsonReturn);

            console.log(jsonReturn);
            this.modelCache.setModel(modelSingularKey, jsonReturn);

            // @ts-ignore
            let cachedModel = this.modelCache.getModel(modelSingularKey, jsonReturn.id) as T;
            console.log(record);
            console.log(cachedModel);
            //keep foundModel the same instance, assign new properties to it
            Object.assign(record, {...cachedModel});

            // Resolve the promise with the updated object
            resolve(record);
          });
      });
    }

    return record;
  }

  private buildHeaders() {
    let headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }

    if (this.accessTokenService) {
      headers['Authorization'] = 'Bearer ' + this.accessTokenService.getToken();
      headers['Id-Token'] = this.accessTokenService.getIdToken();
    }
    return headers;
  }

  public updateModel<T>({modelSingularKey, record}: { modelSingularKey: string, record: Model }) {
    const apiUrl = PrimusBackendInstanceService.getApiUrl();
    if (!apiUrl) {
      throw new Error('[MODEL-STORE] -- API URL not set');
    }

    let modelType = this.modelCache.getModelTypeFor(modelSingularKey);
    let foundAdapter = this.getRegisteredAdpaterFor(modelSingularKey);

    if (modelType && foundAdapter) {
      record.status = 'updating';
      record.loading = null;
      let data = foundAdapter.toJsonObject(record);
      record.loading = new Promise((resolve) => {
        // @ts-ignore
        fetch(apiUrl + foundAdapter.getUrlForId(record.id), {
          method: 'PUT',
          // @ts-ignore
          body: data, ////JSON.stringify(dataObj),
          headers: this.buildHeaders(),
        }).then((response) => response.json())
          .catch((err) => {
            record.status = 'error';
            console.log(err);
          })
          .then((data) => {
            console.log("ModelStore.update -> then: " + modelSingularKey);

            // @ts-ignore
            let jsonReturn: InstanceType<T> = foundAdapter.fromJsonObject(data);

            // Create a new instance of the expected class and assign the properties from the fetched data to it
            jsonReturn = Object.assign(new modelType.classInstance, jsonReturn);

            console.log(jsonReturn);
            this.modelCache.setModel(modelSingularKey, jsonReturn);

            let cachedModel = this.modelCache.getModel(modelSingularKey, record.id) as T;
            //keep foundModel the same instance, assign new properties to it
            Object.assign(record, {...cachedModel});

            // Resolve the promise with the updated object
            resolve(record);
          });
      });
    }

    return record;
  }

  public reloadModel<T extends new (...args: any[]) => any>(modelSingularKey: string, id: string, ...queryParams: UrlQueryParam[]) {
    this.modelCache.deleteModel(modelSingularKey, id);
    return this.findModel(modelSingularKey, id, ...queryParams);
  }
}
