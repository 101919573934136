import {ModelType} from "./ModelType";

export class ModelCache {
    private cache: {[index: string]:any} = {}
    private registeredModels: Map<string, ModelType> = new Map<string, ModelType>

    constructor() {

    }

    public hasCacheFor(singular: string) {
        return this.cache[singular] !== undefined;
    }

    public registerCacheFor(singularKey: string, pluralKey: string, classInstance: any) {
        this.registeredModels.set(singularKey, new ModelType(singularKey, pluralKey, classInstance));
        this.cache[singularKey] = new Map<string, any>();
    }

    public getRegisteredCaches(): ModelType[] {
        let caches: ModelType[] = [];

        if (this.registeredModels !== undefined) {
            this.registeredModels.forEach(modelType=> {
                caches.push(modelType);
            })
        }
        return caches;
    }

    public getModelTypeFor(singularKey: string) : ModelType | undefined {
        return this.registeredModels.get(singularKey);
    }

    public setModel(modelSingularKey: string, json: any) {
        if (!this.hasCacheFor(modelSingularKey)) {
          console.log('NO CACHE FOR MODEL: ' + modelSingularKey, json);
            return;
        }
        if (!json.id) {
            console.log('NO ID FOR MODEL: ' + modelSingularKey, json);
            return;
        }

        this.cache[modelSingularKey].set(json.id, json);
    }

    public setAllModels(modelSingularKey: string, jsons: any[]) {
        if (!this.hasCacheFor(modelSingularKey)) {
            return;
        }

        jsons.forEach(json => {
            this.setModel(modelSingularKey, json);
        })
    }

    public hasModel(modelSingularKey: string, key: string) {
        if (!this.hasCacheFor(modelSingularKey)) {
            return;
        }

        return this.getModel(modelSingularKey, key) !== undefined;
    }
    public getModel(modelSingularKey: string, key: string) {
        if (!this.hasCacheFor(modelSingularKey)) {
            return;
        }

        return this.cache[modelSingularKey].get(key);
    }

    public deleteModel(modelSingularKey: string, key: string) {
        if (!this.hasCacheFor(modelSingularKey)) {
            return;
        }

        this.cache[modelSingularKey].delete(key);
    }

    public clearModels(modelSingularKey: string) {
        if (!this.hasCacheFor(modelSingularKey)) {
            return;
        }

        this.cache[modelSingularKey] = new Map<string, any>();
    }

    public clearEntireCache() {
        this.cache = {};
    }

}
