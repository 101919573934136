import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-admin-selection-action',
  templateUrl: './admin-selection-action.component.html',
  styleUrls: ['./admin-selection-action.component.scss']
})
export class AdminSelectionActionComponent {

  @Input() label: string;
  @Input() action: () => void;

  constructor() {}

  executeAction(): void {
    if (this.action) {
      this.action();
    }
  }
}
