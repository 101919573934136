import {Component, Input, OnChanges} from '@angular/core';
import {ObjectView, OverviewField} from '../../../core/definitions/object-view';
import {OverviewFieldsModule} from '../../../overview-fields/overview-fields.module';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-object-overview-fields-v2',
  standalone: true,
  imports: [
    OverviewFieldsModule,
    TranslateModule
  ],
  templateUrl: './object-overview-fields-v2.component.html',
  styleUrl: './object-overview-fields-v2.component.scss'
})
export class ObjectOverviewFieldsV2Component implements OnChanges {
  @Input() title?: string;
  @Input() objectView: ObjectView;
  @Input() disableLinks: boolean;
  @Input() isEditing: boolean;
  @Input() noTitle: boolean;

  fields: Array<OverviewField> = [];

  ngOnChanges() {
    this.fields = this.getMaxNumberFields(this.objectView.overview);
  }

  private getMaxNumberFields(fields: Array<OverviewField>): Array<OverviewField> {
    return fields && fields.length ? fields.slice(0, 5) : [];
  }
}
