import {Injectable} from '@angular/core';
import {RefData} from './ref.service';
import {LoggerService} from './logger.service';

export interface StateInfo {
  path: string;
  isObjectState: boolean;
  className: string;
}

export interface StateMapper {
  [name: string]: StateInfo;
}

@Injectable({
  providedIn: 'root'
})
export class PrimusStateMapperService {

  private stateMapper: StateMapper = {
    '': {path: '', isObjectState: false, className: 'home'},
    'home': {path: '/home', isObjectState: false, className: 'home'},
    'home.primus': {path: '/primus', isObjectState: false, className: 'home'},
    'home.primus.search': {path: '/search', isObjectState: false, className: 'search-page'},
    'home.primus.advanced-search': {path: '/search/advanced-search', isObjectState: false, className: 'search-page'},
    'home.primus.search.artifact': {path: '/search/artifact', isObjectState: true, className: 'artifact'},
    'home.primus.gallery': {path: '/gallery', isObjectState: true, className: 'body-image-gallery'},
    'home.primus.search.gallery': {path: '/search/gallery', isObjectState: true, className: 'body-image-gallery'},
    'home.primus.videoGallery': {path: '/video-gallery', isObjectState: true, className: 'body-image-gallery'},
    'home.primus.search.videoGallery': {
      path: '/search/video-gallery',
      isObjectState: true,
      className: 'body-image-gallery'
    },
    'home.primus.annotate': {path: '/annotate', isObjectState: true, className: 'body-image-annotate'},
    'home.primus.search.annotate': {path: '/search/annotate', isObjectState: true, className: 'body-image-annotate'},
    'home.primus.artifact': {path: '/artifact', isObjectState: true, className: 'artifact'},
    'home.primus.artifactNew': {path: '/new', isObjectState: true, className: 'artifact'},
    'home.primus.procedure': {path: '/procedure', isObjectState: true, className: 'artifact'},
    'home.primus.search.procedure': {path: '/search/procedure', isObjectState: true, className: 'artifact'},
    'home.primus.information': {path: '/information', isObjectState: true, className: 'artifact'},
    'home.primus.search.information': {path: '/search/information', isObjectState: true, className: 'artifact'},
    'home.primus.faq': {path: '/faq', isObjectState: false, className: 'admin'},
    'home.primus.reports': {path: '/reports', isObjectState: false, className: 'admin'},
    'home.primus.reports.generatedReports': {path: '/reports/generated-reports', isObjectState: false, className: 'admin'},
    'home.primus.reports.generatedReports.report_id': {path: '/reports/generated-reports/:report_id', isObjectState: false, className: 'admin'},
    'home.primus.reports.reportDrafts': {path: '/reports/report-drafts', isObjectState: false, className: 'admin'},
    'home.primus.reports.reportTemplates': {path: '/reports/report-templates', isObjectState: false, className: 'admin'},
    'home.primus.reports.reportTemplates.report_id': {path: '/reports/report-templates/:report_id', isObjectState: false, className: 'admin'},
    'home.primus.reports.standardReports': {path: '/reports/standard-reports', isObjectState: false, className: 'admin'},
    'home.primus.reports.standardReports.report_id': {path: '/reports/standard-reports/:report_id', isObjectState: false, className: 'admin'},
    'home.primus.reportPreview.report_id': {path: '/report-preview/:report_id', isObjectState: false, className: 'admin'},
    'home.primus.about': {path: '/about', isObjectState: false, className: 'admin'},
    'admin-page': {path: '/admin-page', isObjectState: false, className: 'admin'},
    'admin': {path: '/admin', isObjectState: false, className: 'admin'},
    'authenticate': {path: '/authenticate', isObjectState: false, className: 'authenticate'},
    'settings': {path: '/settings', isObjectState: false, className: 'admin'},
    'template-group': {path: '/template-group', isObjectState: false, className: 'admin'},
    'template-model': {path: '/template-model', isObjectState: false, className: 'admin'},
    'concept-lists': {path: '/concept-lists', isObjectState: false, className: 'admin'},
    'collection': {path: '/collection', isObjectState: false, className: 'admin'},
    'id-format': {path: '/id-format', isObjectState: false, className: 'admin'},
    'users': {path: '/users', isObjectState: false, className: 'admin'},
    'user-profile': {path: '/profile', isObjectState: false, className: 'admin'},
    'addresses': {path: '/addresses', isObjectState: false, className: 'admin'},
    'locations': {path: '/locations', isObjectState: false, className: 'admin'},
    'places': {path: '/places', isObjectState: false, className: 'admin'},
    'agents': {path: '/agents', isObjectState: false, className: 'admin'},
    'named-object': {path: '/named-object', isObjectState: false, className: 'admin'},
    'service': {path: '/service', isObjectState: false, className: 'admin'},
    'fields': {path: '/fields', isObjectState: false, className: 'admin'},
    'index': {path: '/index', isObjectState: false, className: 'admin'},
    'mass-media-import': {path: '/mass-media-import', isObjectState: false, className: 'admin'}
  };

  constructor(private logger: LoggerService) {
  }

  routerLinkFromRefData(refData: RefData): any[] {
    return this.routerLinkFromState(refData.stateName);
  }

  queryParamsFromRefData(refData: RefData): any {
    return this.queryParams(refData.param);
  }

  routerLinkFromState(stateName: string) {
    const res = [];
    const mapped = this.stateMapper[stateName];
    if (mapped) {
      res.push(mapped.path);
    } else {
      this.logger.warn(`No state mapping found for ${stateName}`);
    }
    return res;
  }

  matchDynamicURL(url: string, pattern: string) {
    // Replace dynamic parts in the pattern with regex
    const regexPattern = pattern.replace(/:\w+/g, '([^/]+)');
    const regex = new RegExp(`^${regexPattern}$`);
    const match = url.match(regex);

    //return the dynamic part of the URL
    if (match) {
      return match.slice(1);
    }

    //If no match, return null
    return null;
  }

  stateFromRouterLink(path) {
    let res = '';
    for (const stateName in this.stateMapper) {
      if (this.stateMapper[stateName]) {
        const stateData = this.stateMapper[stateName];
        if (stateData.path.indexOf(':') > -1) {
          const match = this.matchDynamicURL(path, stateData.path);
          if (match) {
            res = stateName;
            break;
          }
        } else if (stateData.path === path) {
          res = stateName;
          break;
        }
      }
    }
    return res;
  }

  classNameFromState(stateName: string) {
    const mapped = this.stateMapper[stateName];
    if (mapped) {
      return mapped.className;
    } else {
      this.logger.warn('No state mapping found for ' + stateName);
      return stateName;
    }
  }

  queryParams(stateParams: any, replace = false): any {
    if (!stateParams) {
      return null;
    }
    if (stateParams.$$mapped) {
      return stateParams.$$mapped;
    }
    const params = {};
    for (const [paramName, paramVal] of Object.entries(stateParams)) {
      let value = replace && (paramVal === undefined || paramVal === '') ? null : paramVal;
      if (value === undefined || value === '') {
        continue;
      }
      if (value instanceof Object && !Array.isArray(value)) {
        this.setQueryParamsFromObject(value, params);
      } else {
        params[paramName] = value;
      }
    }
    stateParams.$$mapped = params;
    return params;
  }

  private setQueryParamsFromObject(valueObject: any, params: any) {
    for (const [subName, subVal] of Object.entries(valueObject)) {
      if (subVal !== undefined && subVal !== '') {
        params[subName] = subVal;
      }
    }
  }

  isObjectState(stateName: string) {
    let res = false;
    const stateData = this.stateMapper[stateName];
    if (stateData) {
      res = !!stateData.isObjectState;
    } else {
      this.logger.warn('Unknown state name ' + stateName);
    }
    return res;
  }

}
