<app-m3-wrapper>
  <app-object-page-layout>

    <app-object-page-heading
      slot="heading"
      [title]="artifact?.artifact_name"
      [image]="thumbnailUrl()"
      [loading]="loadingArtifact"
    >
      <div slot="information-popover-content" class="info-popover" (click)="$event.stopPropagation()">

        @if (objectView) {
          <!-- need to include the wrapper again here... the popover is teleported to the outside. -->
          <app-m3-wrapper>
            <app-object-overview-fields-v2
              [objectView]="objectView"
              [isEditing]="isEditing"
            >
              <span slot="title" class="overview-title">
                {{ artifact?.artifact_name }}
              </span>
            </app-object-overview-fields-v2>
        </app-m3-wrapper>
        }
      </div>
    </app-object-page-heading>

    <app-object-page-operations-toolbar
      slot="operations"
      [actions]="headerActions"
      (actionClick)="operationClick($event)"
    ></app-object-page-operations-toolbar>

    <app-object-page-search-navigation
      slot="search-navigation"
      [artifactId]="artifact?.artifact_id"
      [searchContext]="currentSearchContext"
      (backClick)="onBackClick()"
      (forwardClick)="onForwardClick()"
      (closeClick)="onCloseClick()"></app-object-page-search-navigation>

    <div slot="left-drawer" class="flex flex-1 flex-col justify-between">
      @if (loadingNavigationItems) {
        <mat-spinner diameter="20" style="margin: 1rem auto;"></mat-spinner>
      } @else {
        <app-object-page-navigation
          [items]="navigationItems"
          [initialSelectedItem]="initialActiveChild"
        >
        </app-object-page-navigation>

        @if (artifact) {
          <app-m3-global-kpl-styles>
            <app-recto-verso-buttons [object]="artifact"></app-recto-verso-buttons>
          </app-m3-global-kpl-styles>
        }
        @if (contentInfo.artifact) {
          <div class="select-template-group-wrapper">
            <app-select-object-template-button
              [templateGroupId]="contentInfo.templateGroupId"
              [superobject_type_id]="contentInfo.artifact?.superobject_type_id"
              (changeTemplateGroupId)="onChangeTemplateGroup($event)"
            >
            </app-select-object-template-button>
          </div>
        }
      }

    </div>

    <div class="outlet-wrapper">
      <router-outlet></router-outlet>
      @if (selectorContainer()) {
        <app-m3-global-kpl-styles>
          <app-search-view-selector
            [selectorContainer]="selectorContainer()"
          ></app-search-view-selector>
        </app-m3-global-kpl-styles>
      }
    </div>

    <div slot="right-drawer" class="h-full">
      @if (artifact?.$$mediaContainer?.mediaTypes?.length > 0) {
        <app-m3-global-kpl-styles>
          <app-media-carousel
            [startMediaId]="artifact.$$mediaContainer.mediaTypes?.[0]?.mediaElements?.[0]?.mediaObject?.artifact_id"
            [mediaContainer]="artifact.$$mediaContainer"
            [sectionsContainer]="sectionsContainer"
            [curAnn]="curAnn"
            [isEditing]="isEditing"
            (openImageFullScreen)="openImageFullScreen($event)"
            (openModelFullScreen)="openModelFullscreen($event)"
            (objectRefresh)="refreshObject($event)"
          ></app-media-carousel>

          @if (imageFullScreen) {
            <app-image-full-screen
              class="image-full-screen-container"
              [mediaContainer]="artifact.$$mediaContainer"
              (closeImageFullScreen)="closeImageFullScreen()"
              [curAnn]="curAnn"
              [toggleAnnotations]="toggleAnnotations"></app-image-full-screen>
          }

          @if (modelFullscreen) {
            <app-model-full-screen
              class="image-full-screen-container"
              [mediaContainer]="artifact.$$mediaContainer"
              (closeModelFullScreen)="closeModelFullscreen()"
            ></app-model-full-screen>
          }
        </app-m3-global-kpl-styles>
      }
    </div>
  </app-object-page-layout>


</app-m3-wrapper>
